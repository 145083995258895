<template>
  <div :class="`${buttonOrientationClass} my-sm`">
    <div
      class="inline-block custom-button custom-button-slim"
      :class="[typeClasses, props.buttonClass]"
    >
      <NuxtLink
        class="stretched-link"
        :to="`/kontakt?topic=${blockData.contactFormTheme}`"
        target="_self"
      >
        <FaIcon
          v-if="hasIconLeft"
          icon-class="fas fa-chevron-left"
          classes="ml-xs fa-fw"
        />
        {{ t(blockData.label) }}
        <FaIcon
          v-if="hasIconRight"
          icon-class="fas fa-chevron-right"
          classes="mr-xs fa-fw"
        />
      </NuxtLink>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ECallToActionBtnType } from '~~/src/@types/call-to-action-btn-type';
import type { CTAContactData } from './../blockTypes';
import FaIcon from '@/components/fa-icon.vue';

const props = defineProps({
  blockData: {
    type: Object as PropType<CTAContactData>,
    required: true,
    default: {} as CTAContactData,
  },
  buttonClass: {
    type: String,
    required: false,
    default: () => {
      return '';
    },
  },
});

const { t } = useTrans();

const buttonOrientationClass = computed(() => {
  return 'text-' + props.blockData.btnOrientation;
});

const btnType = props.blockData.type ?? ECallToActionBtnType.PRIMARY;
const hasIconLeft = btnType === ECallToActionBtnType.ICON_TEXT_LINK;
const hasIconRight = btnType === ECallToActionBtnType.TEXT_LINK_ICON;

const typeClasses = {
  'text-white !font-medium': props.blockData.textInverted ?? false,
  'bg-vdv-btn-base !font-medium': btnType === ECallToActionBtnType.PRIMARY,
  'bg-vdv-btn-light text-vdv-font': btnType === ECallToActionBtnType.SECONDARY,
  'relative overflow-hidden text-vdv-base transition-all rounded-sm !p-0 place-items-center after:content-[none]':
    btnType === ECallToActionBtnType.ICON_TEXT_LINK ||
    btnType === ECallToActionBtnType.TEXT_LINK_ICON ||
    btnType === ECallToActionBtnType.TEXT_LINK,
  'bg-vdv-btn-cta text-white !font-medium':
    btnType === ECallToActionBtnType.TEXT_LINK_ICON,
};
</script>
